<template>
  <div class="d-flex flex-column pa-2">
    <v-btn text color="primary" v-if="installBtn" @click="installApp"
      >Install App</v-btn
    >

    <v-btn text color="primary" @click="onLogout" :loading="loader"
      >Log out</v-btn
    >

    <v-dialog v-model="dialog" max-width="500">
      <div class="white d-flex flex-column align-center pa-2">
        <v-icon x-large color="warning" class="mb-2">mdi-alert</v-icon>

        <div class="body-1 text-center warning--text mb-4">
          Please finish the videocall before logging out.
        </div>

        <v-btn outlined color="warning" small @click="dialog = false">OK</v-btn>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";

export default {
  data() {
    return {
      loader: false,
      dialog: false,
    };
  },
  computed: {
    ...mapState({
      installEvent: (state) => state.base.installEvent,
      installBtn: (state) => state.base.installBtn,
      callStarted: (state) => state.shipcare.callStarted,
    }),
  },
  methods: {
    ...mapActions(["logout"]),
    ...mapMutations(["toggleInstallBtn"]),
    async installApp() {
      this.toggleInstallBtn(false);

      this.installEvent.prompt();

      const choiceResult = await this.installEvent.userChoice;

      if (choiceResult.outcome === "accepted") {
        console.log("User accepted the install prompt");
      } else {
        console.log("User dismissed the install prompt");
      }
    },
    async onLogout() {
      if (this.callStarted) {
        this.$emit("closeDrawer");
        this.dialog = true;
        this.$vuetify.goTo(99999);
      } else {
        this.loader = true;

        try {
          await this.logout();
        } catch (e) {}

        this.loader = false;
        this.$router.push({ path: "/login" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>