import twilio from "twilio-video";

export default {
    state: {
        localTracks: null,
        room: null,
        remoteVideoState: true,
        remoteConnected: false,
        localTracksMuted: {
            video: false,
            audio: false
        },
    },
    mutations: {
        setLocalTracks(state, localTracks) {
            state.localTracks = localTracks;
        },
        setRoom(state, room) {
            state.room = room;
        },
        setRemoteConnected(state, remoteConnected) {
            state.remoteConnected = remoteConnected;
        },
        changeTrackState(state, payload) {
            state.localTracksMuted[payload.kind] = payload.action == 'mute' ? true : false;
        },
        setRemoteVideoState(state, remoteVideoState) {
            state.remoteVideoState = remoteVideoState;
        },
        resetRoomState(state) {
            state.localTracks = null;
            state.room = null;
            state.remoteVideoState = true;
            state.remoteConnected = false;
            state.localTracksMuted = {
                video: false,
                audio: false
            };
        }
    },
    actions: {
        async getLocalTracks({ commit }) {
            try {
                const tracks = await twilio.createLocalTracks({
                    video: { width: 1920 },
                    audio: true,
                });

                commit('setLocalTracks', tracks);
            } catch (e) {
                throw new Error("setLocalTracks");
            }
        },
        async connectToRoom({ commit, dispatch, state, rootState }) {
            try {
                await dispatch("getLocalTracks");

                const room = await twilio.connect(rootState.shipcare.callData.dispatcherEmployeeTwilioToken,
                    {
                        name: rootState.shipcare.callData.shipRoomSid,
                        tracks: state.localTracks
                    }
                );

                commit('setRoom', room);
            } catch (e) {
                if (e.message == "setLocalTracks") {
                    commit("toggleSnackbar", {
                        open: true,
                        color: "error",
                        text:
                            "The application cannot have access to microphone and/or camera. Please check if your device has a camera / microphone, if it is turned on or if it is already in use by another video calling application (eg Zoom, Microsoft Teams, Skype, etc.)",
                        timeout: -1,
                    });
                } else {
                    commit("toggleSnackbar", {
                        open: true,
                        color: "error",
                        text: "Something went wrong. Please try again later.",
                        timeout: 5000,
                    });
                }

                try {
                    await dispatch("endCall");
                } catch (e) {
                }

                commit("setCallStarted", false);

                throw e;
            }
        },
        changeTrackState({ state, commit }, payload) {
            state.room.localParticipant.tracks.forEach((publication) => {
                if (publication.kind == payload.kind) {
                    if (payload.action == 'mute') {
                        publication.track.disable();
                    } else {
                        publication.track.enable();
                    }
                    commit('changeTrackState', payload);
                }
            })
        }
    },
}
