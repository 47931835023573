<template>
  <form
    class="white d-flex flex-column pa-5 pt-3"
    novalidate
    @submit.prevent="submit"
  >
    <v-btn
      class="align-self-start"
      icon
      small
      @click="hasChanges ? (dialog = true) : $emit('closeDialog')"
      type="button"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>

    <div class="text-h6 text-center mb-5">
      {{ doctorToEdit ? "Edit" : "New" }} Doctor
    </div>

    <v-text-field
      class="mb-1"
      label="Email"
      outlined
      type="email"
      v-model="email"
      :error-messages="emailErrors"
      :disabled="!!doctorToEdit"
    ></v-text-field>

    <v-text-field
      class="mb-1"
      label="First Name"
      outlined
      v-model="firstName"
      :error-messages="firstNameErrors"
    ></v-text-field>

    <v-text-field
      class="mb-1"
      label="Last Name"
      outlined
      v-model="lastName"
      :error-messages="lastNameErrors"
    ></v-text-field>

    <v-text-field
      class="mb-1"
      label="Mobile"
      outlined
      v-model="mobile"
      :error-messages="mobileErrors"
    ></v-text-field>

    <v-autocomplete
      class="mb-1"
      label="Specialty"
      outlined
      :loading="doctorTypesLoader"
      :items="doctorTypes"
      item-text="option_value"
      item-value="option_key"
      v-model="specialty"
      :error-messages="specialtyErrors"
      :disabled="!!doctorToEdit"
    ></v-autocomplete>

    <v-btn
      color="primary"
      class="align-self-center"
      type="submit"
      :loading="loader"
      :disabled="!!doctorToEdit && !hasChanges"
    >
      {{ doctorToEdit ? "Save" : "Add" }}</v-btn
    >

    <v-dialog v-model="dialog" max-width="400">
      <div class="white pa-2">
        <div class="body-1 font-weight-medium text-center mb-2">
          Are you sure you want to discard new doctor before saving?
        </div>

        <div class="d-flex justify-center">
          <v-btn class="mr-2" color="error" small @click="$emit('closeDialog')"
            >discard</v-btn
          >

          <v-btn color="primary" small @click="dialog = false">stay</v-btn>
        </div>
      </div>
    </v-dialog>
  </form>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

const uniqueEmail = function () {
  return !this.emailExists;
};

import {
  required,
  email,
  integer,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";

export default {
  props: ["doctorToEdit"],
  data() {
    return {
      email: "",
      firstName: "",
      lastName: "",
      mobile: "",
      specialty: "",
      doctorTypesLoader: false,
      initialData: null,
      dialog: false,
      loader: false,
      emailExists: false,
    };
  },
  validations: {
    email: { required, email, uniqueEmail },
    firstName: { required },
    lastName: { required },
    mobile: {
      required,
      integer,
      minLength: minLength(10),
      maxLength: maxLength(10),
    },
    specialty: { required },
  },
  computed: {
    ...mapState({
      doctorTypes: (state) => state.base.doctorTypes,
    }),
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      if (!this.$v.email.required) errors.push("Email is required");
      if (!this.$v.email.email) errors.push("Invalid email format");
      if (!this.$v.email.uniqueEmail)
        errors.push("This email address is already being used");
      return errors;
    },
    firstNameErrors() {
      const errors = [];
      if (!this.$v.firstName.$dirty) return errors;
      if (!this.$v.firstName.required) errors.push("First Name is required");
      return errors;
    },
    lastNameErrors() {
      const errors = [];
      if (!this.$v.lastName.$dirty) return errors;
      if (!this.$v.lastName.required) errors.push("Last Name is required");
      return errors;
    },
    mobileErrors() {
      const errors = [];
      if (!this.$v.mobile.$dirty) return errors;
      if (!this.$v.mobile.required) errors.push("Mobile number is required");
      if (!this.$v.mobile.integer)
        errors.push("Mobile number must be a 10 digit number");
      if (!this.$v.mobile.minLength)
        errors.push("Mobile number must be a 10 digit number");
      if (!this.$v.mobile.maxLength)
        errors.push("Mobile number must be a 10 digit number");
      return errors;
    },
    specialtyErrors() {
      const errors = [];
      if (!this.$v.specialty.$dirty) return errors;
      if (!this.$v.specialty.required) errors.push("Specialty is required");
      return errors;
    },
    hasChanges() {
      return (
        JSON.stringify(this.initialData) !==
        JSON.stringify({
          email: this.email,
          firstName: this.firstName,
          lastName: this.lastName,
          mobile: this.mobile,
          specialty: this.specialty,
        })
      );
    },
  },
  async created() {
    if (this.doctorToEdit) {
      this.email = this.doctorToEdit.userProfile.email;
      this.firstName = this.doctorToEdit.userProfile.firstName;
      this.lastName = this.doctorToEdit.userProfile.lastName;
      this.mobile = this.doctorToEdit.userProfile.mobile;
      this.specialty = this.doctorToEdit.doctorAccredations.docLicenseType;
    }

    this.doctorTypesLoader = true;
    await this.getDoctorTypes();
    this.doctorTypesLoader = false;

    this.initialData = {
      email: this.email,
      firstName: this.firstName,
      lastName: this.lastName,
      mobile: this.mobile,
      specialty: this.specialty,
    };
  },
  methods: {
    ...mapActions(["getDoctorTypes", "addDoctor", "editDoctor"]),
    ...mapMutations(["toggleSnackbar"]),
    async submit() {
      this.emailExists = false;

      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.loader = true;

      try {
        if (this.doctorToEdit) {
          await this.editDoctor({
            id: this.doctorToEdit.doctorID,
            email: this.email,
            firstName: this.firstName,
            lastName: this.lastName,
            mobile: this.mobile,
            specialty: this.specialty,
          });
        } else {
          await this.addDoctor({
            email: this.email,
            firstName: this.firstName,
            lastName: this.lastName,
            mobile: this.mobile,
            specialty: this.specialty,
          });
        }

        this.loader = false;

        this.$emit("closeDialog");
      } catch (e) {
        if (e?.response?.data.errorCode == 1002) {
          this.emailExists = true;
        } else {
          this.toggleSnackbar({
            open: true,
            color: "error",
            text: "Something went wrong. Plase, try again later.",
            timeout: -1,
          });
        }

        this.loader = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>