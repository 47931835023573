<template>
  <div>
    <div
      class="toolbar-wrapper py-2 px-4 d-flex justify-center align-center elevation-2"
    >
      <v-btn
        icon
        color="primary"
        large
        class="menu-btn"
        v-if="isLoggedIn && this.$route.path != '/login'"
        @click="drawer = true"
      >
        <v-icon>mdi-menu</v-icon>
      </v-btn>

      <img src="@/assets/logo.svg" />

      <div
        class="body-2 primary--text user-email"
        v-if="userEmail && this.$route.path != '/login'"
      >
        {{ userEmail }}
      </div>
    </div>

    <v-navigation-drawer v-model="drawer" app temporary>
      <Sidebar @closeDrawer="drawer = false" />
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Sidebar from "@/components/Sidebar";

export default {
  components: {
    Sidebar,
  },
  data() {
    return {
      drawer: false,
    };
  },
  computed: {
    ...mapGetters(["isLoggedIn"]),
    ...mapState({
      userEmail: (state) => state.auth.userEmail,
    }),
  },
};
</script>

<style lang="scss" scoped>
.toolbar-wrapper {
  height: 64px;
  position: relative;

  img {
    height: 100%;
  }

  .menu-btn {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
  }

  .user-email {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
  }
}
</style>