<template>
  <div class="component-wrapper d-flex flex-column white pb-3">
    <v-btn class="mb-4" icon small @click="$emit('closePemeDialog')">
      <v-icon> mdi-close </v-icon>
    </v-btn>

    <div class="seafarer-info align-self-center mb-8">
      <v-row>
        <v-col cols="6">
          <div class="body-2 font-weight-medium">Seafarer Name</div>
        </v-col>

        <v-col cols="6">
          <div class="body-2">{{ openCase.seafarerName }}</div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <div class="body-2 font-weight-medium">Seafarer SBN</div>
        </v-col>

        <v-col cols="6">
          <div class="body-2">
            {{ openCase.seafarerSBN }}
          </div>
        </v-col>
      </v-row>

      <v-row v-if="openCase.pemeLink">
        <v-col cols="6">
          <div class="body-2 font-weight-medium">PEME</div>
        </v-col>

        <v-col cols="6">
          <div class="body-2 peme-link" @click="openPeme">
            {{ openCase.pemeLink }}
          </div>
        </v-col>
      </v-row>
    </div>

    <form
      @submit.prevent="submit"
      class="form d-flex flex-column align-self-center"
    >
      <v-file-input
        outlined
        dense
        prepend-inner-icon="mdi-paperclip"
        prepend-icon
        accept="application/pdf"
        label="Upload PEME"
        v-model="peme"
      ></v-file-input>

      <v-btn
        type="submit"
        color="primary"
        class="align-self-center"
        :disabled="!peme"
        :loading="loader"
      >
        Upload
      </v-btn>
    </form>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      peme: null,
      loader: false,
    };
  },
  computed: {
    ...mapState({
      openCase: (state) => state.shipcare.openCase,
    }),
  },
  created() {
    console.log(this.openCase);
  },
  methods: {
    ...mapActions(["uploadPeme"]),
    openPeme() {
      window.open(this.openCase.pemeLink, "_blank");
    },
    async submit() {
      this.loader = true;

      const formData = new FormData();

      formData.set("shipCaseId", this.openCase.shipCaseId);
      formData.set("peme", this.peme);

      try {
        await this.uploadPeme(formData);
        this.loader = false;
        this.$emit("closePemeDialog");
      } catch (e) {
        this.loader = false;
        this.$emit("closePemeDialog");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.seafarer-info {
  width: 300px;
}

.form {
  width: 500px;
}

.peme-link {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--v-primary-base);
  transition: all 0.2s;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    color: var(--v-primary-lighten1);
  }
}
</style>