<template>
  <div class="px-4 d-flex flex-column align-center">
    <v-alert type="error" dense class="mt-4 mb-0" text v-if="!isSupported"
      >This browser does not support video calls.</v-alert
    >

    <v-alert type="error" class="mt-4 mb-0" text dense v-if="!notifSupport"
      >This browser does not support notifications. This is vital to the usage
      of the application.</v-alert
    >

    <v-alert
      type="error"
      class="mt-4 mb-0"
      text
      dense
      v-if="notifPermission == 'denied'"
      >The notifications permission is denied. This is vital to the usage of the
      application.</v-alert
    >

    <v-alert type="error" class="mt-4 mb-0" text dense v-if="!swSupport"
      >This browser does not support service workers. This is vital to the usage
      of the application.</v-alert
    >

    <v-alert type="warning" class="mt-4 mb-0" text dense v-if="showChromeWarn"
      >The recommended browser for this application is the latest version of
      google chrome.</v-alert
    >
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      isSupported: (state) => state.base.isSupported,
      notifSupport: (state) => state.base.notifSupport,
      notifPermission: (state) => state.base.notifPermission,
      swSupport: (state) => state.base.swSupport,
    }),
    showChromeWarn() {
      return !this.isSupported || !this.notifSupport || !this.swSupport;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>