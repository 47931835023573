import axios from 'axios'
import firebase from "firebase/app";
import "firebase/messaging";

export default {
    state: {
        token: localStorage.getItem('token') || null,
        userEmail: localStorage.getItem('userEmail') || null
    },
    getters: {
        isLoggedIn: state => !!state.token
    },
    mutations: {
        login(state, user) {
            state.token = user.token;
            state.userEmail = user.userEmail;
        },
        logout(state) {
            state.token = null;
            state.userEmail = null;
        }
    },
    actions: {
        async login({ commit, dispatch }, credentials) {
            try {
                const res = await axios.post(`${process.env.VUE_APP_BASE_URL}/user/login`, {
                    username: credentials.username,
                    password: credentials.password,
                    application: "app_disp",
                    operatingSystem: "web"
                })

                const token = res.data.token;
                const userEmail = res.data.user.email;

                localStorage.setItem('token', token);
                localStorage.setItem('userEmail', userEmail);
                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

                commit('login', {
                    token,
                    userEmail
                })

                await dispatch("registerNotifications");
            } catch (e) {
                dispatch("logout");

                if (e.message == 'registerNotifications') {
                    commit("toggleSnackbar", {
                        open: true,
                        color: "error",
                        text: "Could not register for push notifications. Plase, check your settings or try again later.",
                        timeout: 5000,
                    })
                } else {
                    if (e?.response?.data.errorCode != 10413 &&
                        e?.response?.data.errorCode != 10414 &&
                        e?.response?.data.errorCode != 10415) {
                        commit("toggleSnackbar", {
                            open: true,
                            color: "error",
                            text: "Something went wrong. Plase, try again later.",
                            timeout: 5000,
                        })
                    }
                }

                throw e;
            }
        },
        async registerNotifications({ commit }) {
            try {
                let token = 'nofcm';

                try {
                    const messaging = firebase.messaging();

                    token = await messaging.getToken({
                        vapidKey:
                            "BKHKaQ_6Pdep2PxwVTA31afHH0YxyhgyETszkh43PEFXOIvTH_zVgE9yqcPrDICKFDHUdO7moflsNTvDt7YchnY",
                    });

                    commit("setNotifPermission", Notification.permission);
                } catch (e) {
                    commit("setNotifPermission", Notification.permission);
                }

                await axios.get(
                    `${process.env.VUE_APP_BASE_URL}/user/notifToken/${token}/web`
                )

            } catch (e) {
                throw new Error("registerNotifications");
            }
        },
        async forgotPassowrd({ commit }, email) {
            try {
                await axios.post(`${process.env.VUE_APP_BASE_URL}/user/requestPassReset`, {
                    email
                })

                commit("toggleSnackbar", {
                    open: true,
                    color: "success",
                    text: `An email has been set to ${email}.`,
                    timeout: 5000
                })
            } catch (e) {
                if (e.response.data?.errorCode != 10403) {
                    commit("toggleSnackbar", {
                        open: true,
                        color: "error",
                        text: "Something went wrong. Try again later.",
                        timeout: -1
                    })
                }
                throw e;
            }
        },
        async logout({ commit }) {
            localStorage.removeItem('token');
            localStorage.removeItem('userEmail');
            delete axios.defaults.headers.common['Authorization'];

            const messaging = firebase.messaging();
            try {
                await messaging.deleteToken();
            } catch (e) {
            }

            commit('logout');
            commit('resetShipcareState');
        }
    },
}
