import axios from "axios"
import { isSupported } from "twilio-video";

export default {
    state: {
        installEvent: null,
        installBtn: null,
        snackbar: {
            open: false,
            text: null,
            color: null,
            timeout: null
        },
        doctorTypes: [],
        doctorTypesFetched: false,
        isSupported,
        notifSupport: true,
        notifPermission: null,
        swSupport: true,
        browserSupportDialog: false
    },
    getters: {
        allowCall(state) {
            return state.isSupported && state.notifSupport && state.notifPermission == 'granted' && state.swSupport
        }
    },
    mutations: {
        saveInstallEvent(state, event) {
            state.installEvent = event;
        },
        toggleInstallBtn(state, installBtn) {
            state.installBtn = installBtn;
        },
        toggleSnackbar(state, snackbar) {
            state.snackbar = {
                ...state.snackbar,
                ...snackbar
            };
        },
        getDoctorTypes(state, doctorTypes) {
            state.doctorTypes = doctorTypes;
        },
        setDoctorsTypesFetched(state, doctorTypesFetched) {
            state.doctorTypesFetched = doctorTypesFetched;
        },
        setNotifSupport(state, notifSupport) {
            state.notifSupport = notifSupport;
        },
        setNotifPermission(state, notifPermission) {
            state.notifPermission = notifPermission;
        },
        setSwSupport(state, swSupport) {
            state.swSupport = swSupport;
        },
        setBrowserSupportDialog(state, browserSupportDialog) {
            state.browserSupportDialog = browserSupportDialog;
        }
    },
    actions: {
        init({ commit }) {
            if (!("Notification" in window)) {
                commit("setNotifSupport", false)
            }

            if (!('serviceWorker' in navigator)) {
                commit("setSwSupport", false)
            }

            commit("setNotifPermission", Notification.permission);
        },
        async getDoctorTypes({ state, commit }) {
            if (state.doctorTypesFetched) return;

            try {

                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/optionsList/doctorTypes/en`);

                commit("getDoctorTypes", res.data);
                commit("setDoctorsTypesFetched", true);

            } catch (e) {
                throw e;
            }
        },
    },
}
