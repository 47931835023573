<template>
  <div class="white d-flex flex-column align-center pa-2">
    <v-icon x-large color="error" class="mb-2">mdi-alert</v-icon>

    <div class="body-1 text-center error--text mb-4">
      Videocall is not allowed due to some key features of the application not
      being supported by this browser.
    </div>

    <v-btn outlined color="error" small @click="$emit('closeDialog')">OK</v-btn>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>