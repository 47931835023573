<template>
  <div class="d-flex justify-center">
    <div class="elevation-2 rounded-b videocall-card d-flex flex-column">
      <div class="primary text-center pa-2 text-h6 white--text rounded-t">
        Ship: {{ callData.shipName }}, Master:
        {{ callData.shipMasterName }}
      </div>

      <div class="videocall-card-inner align-self-center pa-4">
        <div
          class="remote rounded"
          ref="remote"
          :class="{ 'not-connected': !remoteConnected }"
        >
          <div v-if="!remoteConnected">
            <div class="body-1 primary--text text-center mb-1">
              Connecting...
            </div>

            <DotLoader v-if="connected && !remoteConnected" />
          </div>

          <div
            class="camera-muted-msg white--text body-1 text-center"
            v-if="!remoteVideoState && remoteConnected"
          >
            The ship captain has temporarily turned off his camera
          </div>
        </div>

        <div class="pt-2 d-flex justify-center">
          <Buttons />
        </div>

        <div class="local rounded elevation-5" ref="local" v-show="connected">
          <div
            class="camera-muted-msg white--text body-1 text-center"
            v-if="localTracksMuted.video"
          >
            Your camera is off
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Buttons from "@/views/Buttons";
import DotLoader from "@/components/DotLoader";

import { mapState, mapMutations, mapActions } from "vuex";

export default {
  components: {
    Buttons,
    DotLoader,
  },
  data() {
    return {
      connected: false,
      remoteConnected: false,
    };
  },
  computed: {
    ...mapState({
      callData: (state) => state.shipcare.callData,
      room: (state) => state.twilio.room,
      localTracks: (state) => state.twilio.localTracks,
      localTracksMuted: (state) => state.twilio.localTracksMuted,
      remoteVideoState: (state) => state.twilio.remoteVideoState,
      snackbar: (state) => state.base.snackbar,
    }),
  },
  async mounted() {
    try {
      await this.connectToRoom();

      if (this.room) {
        this.room.localParticipant.videoTracks.forEach((track) => {
          this.$refs.local.appendChild(track.track.attach());
        });

        this.connected = true;

        //====== HANDLE CONNECTED PARTICIPANTS ======
        this.room.participants.forEach((participant) => {
          console.log(
            `Participant "${participant.identity}" already connected`
          );

          participant.tracks.forEach((publication) => {
            if (publication.isSubscribed) {
              const track = publication.track;
              this.$refs.remote.appendChild(track.attach());

              if (publication.kind == "video") {
                this.setRemoteVideoState(publication.isTrackEnabled);
              }
            }
          });

          participant.on("trackSubscribed", (track) => {
            this.$refs.remote.appendChild(track.attach());
            this.setRemoteVideoState(track.isEnabled);
          });

          participant.on("trackDisabled", (publication) => {
            if (publication.kind == "video") {
              this.setRemoteVideoState(publication.isTrackEnabled);
            }
          });

          participant.on("trackEnabled", (publication) => {
            if (publication.kind == "video") {
              this.setRemoteVideoState(publication.isTrackEnabled);
            }
          });

          this.remoteConnected = true;
          this.setRemoteConnected(true);
        });

        //====== HANDLE REMOTE PARTICIPANTS EVENTS ======

        //------ participant connected ------
        this.room.on("participantConnected", (participant) => {
          console.log(`Participant "${participant.identity}" connected`);

          participant.tracks.forEach((publication) => {
            if (publication.isSubscribed) {
              const track = publication.track;
              this.$refs.remote.appendChild(track.attach());

              if (publication.kind == "video") {
                this.setRemoteVideoState(publication.isTrackEnabled);
              }
            }
          });

          participant.on("trackSubscribed", (track) => {
            this.$refs.remote.appendChild(track.attach());

            if (track.kind == "video") {
              this.setRemoteVideoState(track.isEnabled);
            }
          });

          participant.on("trackDisabled", (publication) => {
            if (publication.kind == "video") {
              this.setRemoteVideoState(publication.isTrackEnabled);
            }
          });

          participant.on("trackEnabled", (publication) => {
            if (publication.kind == "video") {
              this.setRemoteVideoState(publication.isTrackEnabled);
            }
          });

          this.remoteConnected = true;
          this.setRemoteConnected(true);
        });

        //------ participant disconnected ------
        this.room.on("participantDisconnected", (participant) => {
          console.log(`Participant disconnected: ${participant.identity}`);
        });

        //====== HANDLE ROOM DISCONNECTION ======
        this.room.on("disconnected", (room) => {
          console.log("disconnected from room");
          this.setCallStarted(false);
        });
      }
    } catch (e) {}
  },
  methods: {
    ...mapActions(["connectToRoom", "endCall"]),
    ...mapMutations([
      "setCallStarted",
      "toggleSnackbar",
      "setRemoteConnected",
      "setRemoteVideoState",
      "resetRoomState",
      "setDoctorsCalled"
    ]),
  },
  destroyed() {
    this.localTracks?.forEach((track) => {
      track.stop();
      track.detach();
    });

    if (this.room?.state == "connected") this.room?.disconnect();

    this.resetRoomState();
    this.setDoctorsCalled(false);

    if (this.remoteConnected && !this.snackbar.open) {
      this.setRemoteConnected(false);
      this.toggleSnackbar({
        open: true,
        color: "warning",
        text: "Call Ended.",
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.videocall-card {
  .videocall-card-inner {
    position: relative;
  }

  .remote {
    height: 480px;
    width: 640px;

    @media only screen and (max-width: 1420px) {
      height: 420px;
      width: 560px;
    }

    &.not-connected {
      border: 1px solid #e0e0e0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  .local {
    position: absolute;
    right: 16px;
    bottom: 16px;
    height: 120px;
    width: 160px;
  }
}

.camera-muted-msg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>