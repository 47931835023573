<template>
  <div class="d-flex align-center">
    <v-btn icon color="white" class="mr-2 red" @click="dialog = true">
      <v-icon>mdi-phone-hangup</v-icon>
    </v-btn>

    <v-btn
      icon
      color="white"
      class="mr-2"
      :class="{ 'grey lighten-1': !room, primary: !!room }"
      :disabled="!room"
      @click="
        changeTrackState({
          kind: 'video',
          action: localTracksMuted.video ? 'unmute' : 'mute',
        })
      "
    >
      <v-icon>{{
        localTracksMuted.video ? "mdi-video-off" : "mdi-video"
      }}</v-icon>
    </v-btn>

    <div class="mic-btn-wrapper">
      <div
        class="tooltip caption grey white--text"
        v-if="localTracksMuted.audio"
      >
        Σε σίγαση
      </div>

      <v-btn
        icon
        color="white"
        :class="{ 'grey lighten-1': !room, primary: !!room }"
        :disabled="!room"
        @click="
          changeTrackState({
            kind: 'audio',
            action: localTracksMuted.audio ? 'unmute' : 'mute',
          })
        "
      >
        <v-icon>{{
          localTracksMuted.audio ? "mdi-microphone-off" : "mdi-microphone"
        }}</v-icon>
      </v-btn>
    </div>

    <v-dialog v-model="dialog" max-width="500">
      <div class="white pa-4">
        <div class="body-1 text-center mb-2">
          Do you wish to leave the call?
        </div>

        <div class="d-flex justify-center">
          <v-btn
            class="mr-2"
            small
            color="error"
            @click="onEndCall"
            :loading="loader"
            >Leave</v-btn
          >

          <v-btn small color="primary" @click="dialog = false">Stay</v-btn>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  data() {
    return {
      dialog: false,
      loader: false,
    };
  },
  computed: {
    ...mapState({
      room: (state) => state.twilio.room,
      localTracksMuted: (state) => state.twilio.localTracksMuted,
    }),
  },
  methods: {
    ...mapActions(["endCall", "changeTrackState"]),
    ...mapMutations(["toggleSnackbar", "setCallStarted"]),
    async onEndCall() {
      this.loader = true;

      try {
        await this.endCall();
      } catch (e) {
        this.toggleSnackbar({
          open: true,
          color: "error",
          text: "Something went wrong. Please try again later.",
          timeout: -1,
        });
      }

      this.setCallStarted(false);

      this.loader = false;
      this.dialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.mic-btn-wrapper {
  position: relative;

  .tooltip {
    position: absolute;
    top: -35px;
    left: 50%;
    transform: translateX(-50%);
    padding: 1px 8px;
    border-radius: 3px;
    white-space: nowrap;
  }
}
</style>