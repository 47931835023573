<template>
  <div class="component-wrapper d-flex flex-column justify-start">
    <div class="d-flex mb-5">
      <Cases />

      <Doctors />
    </div>

    <Room v-if="callStarted" />
  </div>
</template>

<script>
import Doctors from "@/views/Doctors";
import Cases from "@/views/Cases";
import Room from "@/views/Room";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    Doctors,
    Cases,
    Room,
  },
  computed: {
    ...mapState({
      callStarted: (state) => state.shipcare.callStarted,
    }),
  },
  methods: {
    ...mapActions(["getCases"]),
  },
  watch: {
    callStarted(newValue) {
      if (newValue) {
        this.$vuetify.goTo(99999);
      } else {
        this.getCases(true);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>