<template>
  <div class="elevation-2 rounded-b pb-2 data-card d-flex flex-column">
    <div class="primary text-center pa-1 text-h6 white--text rounded-t">
      Doctors
    </div>

    <div class="d-flex align-center my-2 px-4">
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        class="flex-grow-1 mr-4"
        outlined
        dense
        label="Search"
        single-line
        hide-details
      ></v-text-field>

      <v-btn-toggle v-model="status" dense group color="primary">
        <v-btn value="all" class="ma-0"> All </v-btn>

        <v-btn value="online" class="ma-0"> Online </v-btn>
      </v-btn-toggle>

      <v-btn small color="primary" class="ml-4" @click="dialog = true">
        <v-icon left>mdi-plus</v-icon>
        New Doctor
      </v-btn>
    </div>

    <v-data-table
      :headers="headers"
      :items="filteredDoctors"
      :items-per-page="8"
      :search="search"
      hide-default-footer
      @page-count="pageCount = $event"
      :page.sync="page"
      :sort-by.sync="sortby"
      class="rounded-0 rounded-b mb-2"
      :loading="doctorsLoader"
    >
      <template #item="{ item }">
        <tr>
          <td class="px-2 specialty-text">
            {{ item.doctorAccredations.doctorTypeName }}
          </td>

          <td class="px-1">
            {{ item.userProfile.lastName }} {{ item.userProfile.firstName }}
          </td>

          <td class="px-1">{{ item.userProfile.mobile }}</td>

          <td class="px-1">
            <div class="d-flex align-center">
              <div
                class="status-bullet mr-3"
                :class="{
                  success: item.isCurrentlyShipCallAvailable,
                  warning: !item.isCurrentlyShipCallAvailable,
                }"
              ></div>

              <v-btn
                fab
                x-small
                color="primary"
                :loading="
                  statusLoader.open && statusLoader.doctorID == item.doctorID
                "
                @click="onChangeStatus(item)"
              >
                <v-icon color="white">mdi-sync</v-icon>
              </v-btn>
            </div>
          </td>

          <td class="px-1">
            <v-btn fab x-small color="primary" @click="onEditDoctor(item)">
              <v-icon small color="white">mdi-pencil</v-icon>
            </v-btn>
          </td>

          <td class="px-1">
            <v-btn
              fab
              x-small
              color="primary"
              :disabled="!remoteConnected || !item.isCurrentlyShipCallAvailable"
              @click="onCallDoctor(item)"
              :loading="callDoctorLoader && callDoctorId == item.doctorID"
            >
              <v-icon small color="white">mdi-phone</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>

    <v-pagination
      class="mt-auto"
      v-model="page"
      :length="pageCount"
    ></v-pagination>

    <v-dialog v-model="dialog" max-width="500" persistent>
      <NewDoctor
        v-if="dialog"
        @closeDialog="dialog = false"
        :doctorToEdit="doctorToEdit"
      />
    </v-dialog>

    <v-dialog v-model="doctorNotifiedDialog.open" max-width="500" persistent>
      <div class="white d-flex flex-column align-center pa-4">
        <v-icon x-large color="primary" class="mb-2">mdi-alert</v-icon>

        <div
          class="body-1 text-center primary--text mb-4"
          v-if="doctorNotifiedDialog.doctor"
        >
          You have already notified doctor
          <b
            >{{ doctorNotifiedDialog.doctor.userProfile.firstName }}
            {{ doctorNotifiedDialog.doctor.userProfile.lastName }}</b
          >
          from this case
        </div>

        <v-btn
          outlined
          color="primary"
          small
          @click="doctorNotifiedDialog.open = false"
          >OK</v-btn
        >
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import NewDoctor from "@/views/NewDoctor";

export default {
  components: {
    NewDoctor,
  },
  data() {
    return {
      page: 1,
      pageCount: 0,
      search: "",
      status: "all",
      sortby: "lastName",
      sortDesc: false,
      headers: [
        { text: "Specialty", value: "doctorAccredations.doctorTypeName" },
        { text: "Name", value: "userProfile.lastName" },
        { text: "Mobile", value: "userProfile.mobile", sortable: false },
        {
          text: "Available",
          value: "isCurrentlyShipCallAvailable",
          sortable: false,
        },
        { text: "Edit", sortable: false },
        { text: "Call", sortable: false },
      ],
      callDoctorLoader: false,
      callDoctorId: null,
      dialog: false,
      statusLoader: {
        open: false,
        doctorID: null,
      },
      doctorToEdit: null,
      doctorNotifiedDialog: {
        open: false,
        doctor: null,
      },
    };
  },
  computed: {
    ...mapState({
      doctors: (state) => state.shipcare.doctors,
      doctorsLoader: (state) => state.shipcare.doctorsLoader,
      remoteConnected: (state) => state.twilio.remoteConnected,
      doctorsCalled: (state) => state.shipcare.doctorsCalled,
    }),
    filteredDoctors() {
      return this.doctors.filter((doctor) =>
        this.status == "all" ? true : doctor.isCurrentlyShipCallAvailable
      );
    },
  },
  created() {
    this.getDoctors();
  },
  methods: {
    ...mapActions(["getDoctors", "callDoctor", "changeDoctorStatus"]),
    async onCallDoctor(item) {
      this.callDoctorId = item.doctorID;

      if (this.doctorsCalled.indexOf(this.callDoctorId) == -1) {
        this.callDoctorLoader = true;

        try {
          await this.callDoctor(item.doctorID);

          this.$vuetify.goTo(99999);
        } catch (e) {}

        this.callDoctorLoader = false;
      } else {
        this.doctorNotifiedDialog = {
          open: true,
          doctor: item,
        };
      }
    },
    async onChangeStatus(doctor) {
      this.statusLoader = {
        open: true,
        doctorID: doctor.doctorID,
      };

      try {
        await this.changeDoctorStatus({
          doctorID: doctor.doctorID,
          status: !doctor.isCurrentlyShipCallAvailable,
        });
      } catch (e) {}

      this.statusLoader = {
        open: false,
        doctorID: null,
      };
    },
    onEditDoctor(doctor) {
      this.doctorToEdit = doctor;
      this.dialog = true;
    },
  },
  watch: {
    dialog(newValue) {
      if (!newValue) {
        this.doctorToEdit = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.status-bullet {
  height: 12px;
  width: 12px;
  border-radius: 50%;
}
</style>