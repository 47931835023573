<template>
  <div class="elevation-2 mr-5 rounded-b pb-2 data-card d-flex flex-column">
    <div class="primary text-center pa-1 text-h6 white--text rounded-t">
      Latest Cases
    </div>

    <div class="d-flex align-center my-2 px-4">
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        class="flex-grow-1 mr-2"
        outlined
        dense
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </div>

    <v-data-table
      :headers="headers"
      :items="casesMapped"
      :items-per-page="8"
      :search="search"
      hide-default-footer
      @page-count="pageCount = $event"
      :page.sync="page"
      :sort-by.sync="sortby"
      :custom-sort="customSort"
      :sort-desc.sync="sortDesc"
      class="rounded-0 rounded-b mb-2"
      :loading="casesLoader"
    >
      <template #item="{ item }">
        <tr
          :class="{
            'red lighten-2 white--text': item.status == 'pending',
            'warning grey--text text--darken-4': item.status == 'callended',
            'yellow  grey--text text--darken-4': item.status == 'followup',
            'green lighten-1 white--text': item.status == 'closed',
          }"
        >
          <td class="px-1">{{ item.lastAlertDate.format("DD/MM/YYYY") }}</td>
          <td class="px-1">{{ item.shipOwnerName }}</td>
          <td class="px-1">{{ item.shipName }}</td>
          <td class="px-1">{{ item.startMasterName }}</td>
          <td class="px-1">{{ item.seafarerName }}</td>

          <td class="px-1">
            <v-btn
              width="82px"
              color="primary"
              x-small
              @click="onOpenPemeDialog(item)"
              >{{ item.seafarerSBN }}</v-btn
            >
          </td>

          <td class="px-1 d-flex justify-center align-center">
            <v-btn
              small
              width="50px"
              color="primary"
              @click="onOpenCallsDialog(item)"
              >{{ item.totalCallCount }}</v-btn
            >
          </td>

          <td class="px-1">
            <v-btn
              fab
              x-small
              color="primary"
              :disabled="callStarted"
              @click="callShip(item)"
            >
              <v-icon small color="white">mdi-phone</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>

    <v-pagination
      class="mt-auto"
      v-model="page"
      :length="pageCount"
    ></v-pagination>

    <v-dialog
      v-model="callShipDialog"
      class="align-self-center"
      max-width="500"
    >
      <CaseCallDialog @closeDialog="callShipDialog = false" />
    </v-dialog>

    <v-dialog v-model="callsDialog" class="align-self-center" max-width="800">
      <Calls v-if="callsDialog" @closeCallsDialog="callsDialog = false" />
    </v-dialog>

    <v-dialog v-model="pemeDialog" class="align-self-center" max-width="800">
      <UploadPeme v-if="pemeDialog" @closePemeDialog="pemeDialog = false" />
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import CaseCallDialog from "@/views/CaseCallDialog";
import Calls from "@/views/Calls";
import UploadPeme from "@/views/UploadPeme";

export default {
  components: {
    CaseCallDialog,
    Calls,
    UploadPeme,
  },
  data() {
    return {
      page: 1,
      pageCount: 0,
      search: "",
      sortby: "",
      sortDesc: true,
      headers: [
        { text: "Date", value: "lastAlertDate" },
        { text: "Company", value: "shipOwnerName" },
        { text: "Ship", value: "shipName" },
        { text: "Master", value: "startMasterName" },
        { text: "Seafearer", value: "seafarerName" },
        { text: "SBN / PEME", value: "seafarerSBN" },
        { text: "Number of Calls", value: "totalCallCount" },
        { text: "Call", value: "call", sortable: false },
      ],
      callShipDialog: false,
      callsDialog: false,
      pemeDialog: false,
    };
  },
  computed: {
    ...mapGetters(["allowCall"]),
    ...mapState({
      cases: (state) => state.shipcare.cases,
      casesLoader: (state) => state.shipcare.casesLoader,
      callStarted: (state) => state.shipcare.callStarted,
    }),
    casesMapped() {
      return this.cases.map((c) => {
        return {
          ...c,
          lastAlertDate: moment(c.lastAlertDate),
        };
      });
    },
  },
  created() {
    this.getCases();
  },
  methods: {
    ...mapActions(["getCases"]),
    ...mapMutations([
      "selectCase",
      "setOpenCase",
      "setOpenCaseCalls",
      "setBrowserSupportDialog",
    ]),
    customSort(items, sortBy, sortDesc) {
      sortBy = sortBy[0];
      sortDesc = sortDesc[0];

      items.sort((a, b) => {
        if (sortBy === "lastAlertDate") {
          if (!sortDesc) {
            const dateA = a[sortBy];
            const dateB = b[sortBy];
            return dateA.diff(dateB);
          } else {
            const dateA = b[sortBy];
            const dateB = a[sortBy];
            return dateA.diff(dateB);
          }
        } else {
          if (!sortDesc) {
            return a[sortBy] < b[sortBy] ? -1 : 1;
          } else {
            return b[sortBy] < a[sortBy] ? -1 : 1;
          }
        }
      });

      return items;
    },
    callShip(shipCase) {
      if (this.allowCall) {
        this.selectCase(shipCase);
        this.callShipDialog = true;
      } else {
        this.setBrowserSupportDialog(true);
      }
    },
    onOpenCallsDialog(shipCase) {
      this.setOpenCase(shipCase);
      this.callsDialog = true;
    },
    onOpenPemeDialog(shipCase) {
      this.setOpenCase(shipCase);
      this.pemeDialog = true;
    },
  },
  watch: {
    callsDialog(newValue) {
      if (!newValue) {
        this.setOpenCase(null);
        this.setOpenCaseCalls([]);
      }
    },
    casesLoader() {
      this.sortby = "";
    },
  },
};
</script>

<style lang="scss" >
.v-data-table-header {
  tr {
    th {
      white-space: nowrap;
    }
  }
}
</style>