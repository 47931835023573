<template>
  <div class="white pa-4" v-if="selectedCase">
    <div class="body-1 text-center mb-4">
      Do you wish to call master <b>{{ selectedCase.startMasterName }}</b> of
      ship <b>{{ selectedCase.shipName }}</b> ?
    </div>

    <div class="d-flex justify-center">
      <v-btn
        class="mr-2"
        small
        color="primary"
        @click="onCallShip"
        :loading="loader"
        >Call</v-btn
      >

      <v-btn small color="error" @click="$emit('closeDialog')">Cancel</v-btn>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      loader: false,
    };
  },
  computed: {
    ...mapState({
      selectedCase: (state) => state.shipcare.selectedCase,
    }),
  },
  methods: {
    ...mapActions(["callShip"]),
    async onCallShip() {
      this.loader = true;

      try {
        await this.callShip();
      } catch (e) {}

      this.$emit("closeDialog");
      this.loader = false;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>