import axios from "axios"

export default {
    state: {
        cases: [],
        casesFetched: false,
        casesLoader: false,
        doctors: [],
        doctorsFetched: false,
        doctorsLoader: false,
        selectedCase: null,
        callData: null,
        callStarted: false,
        openCase: null,
        openCaseCalls: [],
        doctorsCalled: []
    },
    mutations: {
        getDoctors(state, doctors) {
            state.doctors = doctors;
        },
        setDoctorsFetched(state, doctorsFetched) {
            state.doctorsFetched = doctorsFetched;
        },
        toggleDoctorsLoader(state, doctorsLoader) {
            state.doctorsLoader = doctorsLoader
        },
        getCases(state, cases) {
            state.cases = cases;
        },
        setCasesFetched(state, casesFetched) {
            state.casesFetched = casesFetched;
        },
        toggleCasesLoader(state, casesLoader) {
            state.casesLoader = casesLoader
        },
        selectCase(state, shipCase) {
            state.selectedCase = shipCase;
        },
        setCallData(state, callData) {
            state.callData = callData;
        },
        setCallStarted(state, callStarted) {
            state.callStarted = callStarted;
        },
        setOpenCase(state, shipCase) {
            state.openCase = shipCase;
        },
        setOpenCaseCalls(state, openCaseCalls) {
            state.openCaseCalls = openCaseCalls;
        },
        setDoctorsCalled(state, doctorId) {
            if (!doctorId) {
                state.doctorsCalled = [];
            } else {
                state.doctorsCalled.push(doctorId);
            }
        },
        resetShipcareState(state) {
            state.cases = [];
            state.casesFetched = false;
            state.casesLoader = false;
            state.doctors = [];
            state.doctorsFetched = false;
            state.doctorsLoader = false;
            state.selectedCase = null;
            state.callData = null;
            state.callStarted = false;
            state.openCase = null;
            state.openCaseCalls = [];
            state.doctorsCalled = [];
        }
    },
    actions: {
        async getCases({ commit, state }, refresh = false) {
            if (state.casesFetched && !refresh) return;

            try {
                commit("getCases", []);
                commit("toggleCasesLoader", true);

                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/dispatcher/cases`)

                commit("getCases", res?.data);
                commit("setCasesFetched", true);
                commit("toggleCasesLoader", false);
            } catch (e) {
                commit("toggleSnackbar", {
                    open: true,
                    text: "Something went wrong. Plase, try again later.",
                    color: "error",
                    timeout: -1,
                })
                throw e;
            }
        },
        async getDoctors({ commit, state }, refresh = false) {
            if (state.doctorsFetched && !refresh) return;

            try {
                commit("getDoctors", []);
                commit("toggleDoctorsLoader", true);
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/dispatcher/doctors`)

                commit("getDoctors", res?.data);
                commit("setDoctorsFetched", true);
                commit("toggleDoctorsLoader", false);

            } catch (e) {
                commit("toggleSnackbar", {
                    open: true,
                    text: "Something went wrong. Plase, try again later.",
                    color: "error",
                    timeout: -1,
                })
                throw e;
            }
        },
        async callShip({ commit, state }) {
            try {
                const res = await axios.post(`${process.env.VUE_APP_BASE_URL}/dispatcher/callShip`, {
                    shipCaseId: state.selectedCase.shipCaseId
                })

                commit("setCallStarted", true);

                commit("setCallData", res.data);
            } catch (e) {
                commit("toggleSnackbar", {
                    open: true,
                    text: "Something went wrong. Plase, try again later.",
                    color: "error",
                    timeout: -1,
                })

                commit("setCallStarted", false);

                commit("setCallData", null);
                throw e;
            }
        },
        async endCall({ state }) {
            try {
                await axios.post(`${process.env.VUE_APP_BASE_URL}/dispatcher/callend`, {
                    shipCallRoom: state.callData.shipCallRoom,
                });
            } catch (e) {
                throw e;
            }
        },
        async callDoctor({ state, commit }, doctorId) {
            try {
                const res = await axios.post(`${process.env.VUE_APP_BASE_URL}/dispatcher/calldoctor`, {
                    shipCallRoom: state.callData.shipCallRoom,
                    doctorId
                });

                commit("setDoctorsCalled", doctorId);

                commit("toggleSnackbar", {
                    open: true,
                    color: "success",
                    text: `You have notified Dr. ${res.data.doctorFirstName} ${res.data.doctorLastName} !`,
                });
            } catch (e) {
                commit("toggleSnackbar", {
                    open: true,
                    text: "Something went wrong. Plase, try again later.",
                    color: "error",
                    timeout: -1,
                })

                throw e;
            }
        },
        async getOpenCaseCalls({ state, commit }) {
            try {

                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/dispatcher/casecalls/${state.openCase.shipCaseId}`);

                commit("setOpenCaseCalls", res.data);

            } catch (e) {
                commit("toggleSnackbar", {
                    open: true,
                    text: "Something went wrong. Plase, try again later.",
                    color: "error",
                    timeout: -1,
                })

                throw e;
            }
        },
        async addDoctor({ commit, dispatch }, doctor) {
            try {

                await axios.post(`${process.env.VUE_APP_BASE_URL}/dispatcher/adddoctor`, {
                    userProfile: {
                        email: doctor.email,
                        firstName: doctor.firstName,
                        lastName: doctor.lastName,
                        mobile: doctor.mobile,
                    },
                    doctorAccredations: {
                        docLicenseType: doctor.specialty
                    }
                });

                dispatch("getDoctors", true);

                commit("toggleSnackbar", {
                    open: true,
                    text: "New doctor has been added!",
                    color: "success",
                    timeout: 5000
                })

            } catch (e) {
                throw e;
            }
        },
        async editDoctor({ commit, dispatch }, doctor) {
            try {

                await axios.post(`${process.env.VUE_APP_BASE_URL}/dispatcher/adddoctor`, {
                    userProfile: {
                        doctorId: doctor.id,
                        email: doctor.email,
                        firstName: doctor.firstName,
                        lastName: doctor.lastName,
                        mobile: doctor.mobile,
                    },
                    doctorAccredations: {
                        docLicenseType: doctor.specialty
                    }
                });

                dispatch("getDoctors", true);

                commit("toggleSnackbar", {
                    open: true,
                    text: "doctor has been updated!",
                    color: "success",
                    timeout: 5000
                })

            } catch (e) {
                throw e;
            }
        },
        async changeDoctorStatus({ dispatch, commit }, payload) {
            try {
                await axios.post(`${process.env.VUE_APP_BASE_URL}/dispatcher/setDoctorAvailability`, {
                    doctorID: payload.doctorID,
                    isCurrentlyShipCallAvailable: payload.status
                })

                dispatch("getDoctors", true);
            } catch (e) {
                commit("toggleSnackbar", {
                    open: true,
                    color: "error",
                    text: "Something went wrong. Please try again later.",
                    timeout: -1
                })

                throw e;
            }
        },
        async uploadPeme({ commit, dispatch }, peme) {
            try {
                await axios.post(`${process.env.VUE_APP_BASE_URL}/dispatcher/setseafarerpeme`, peme);

                commit("toggleSnackbar", {
                    open: true,
                    color: "success",
                    text: "Peme has been uploaded!",
                    timeout: 5000
                })

                dispatch("getCases", true);

            } catch (e) {
                commit("toggleSnackbar", {
                    open: true,
                    color: "error",
                    text: "Something went wrong. Please try again later.",
                    timeout: -1
                })

                throw e;
            }
        }
    },
}
