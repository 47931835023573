<template>
  <v-app>
    <audio ref="notif" src="notif.mp3" preload="auto"></audio>
    <Navigation />

    <Alerts v-if="isLoggedIn && this.$route.path != '/login'" />

    <v-main>
      <router-view></router-view>
    </v-main>

    <v-snackbar
      v-model="snackbar.open"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
    >
      <div class="d-flex align-center">
        <div class="body-2 mr-2">{{ snackbar.text }}</div>
        <v-btn text small class="ml-auto" @click="onCloseSnackbar">Close</v-btn>
      </div>
    </v-snackbar>

    <v-dialog v-model="browserSupportDialog" persistent max-width="500">
      <BrowserSupportDialog @closeDialog="browserSupportDialog = false" />
    </v-dialog>

    <Footer />
  </v-app>
</template>

<script>
import Navigation from "@/components/Navigation";
import Footer from "@/components/Footer";
import Alerts from "@/components/Alerts";
import BrowserSupportDialog from "@/components/BrowserSupportDialog";
import { mapGetters, mapActions, mapState, mapMutations } from "vuex";
import axios from "axios";
import firebase from "firebase/app";
import "firebase/messaging";

import { Plugins } from "@capacitor/core";

const { App } = Plugins;

export default {
  components: {
    Navigation,
    Footer,
    Alerts,
    BrowserSupportDialog,
  },
  data() {
    return {
      refetchCases: false,
    };
  },
  computed: {
    ...mapGetters(["isLoggedIn"]),
    ...mapState({
      snackbar: (state) => state.base.snackbar,
      token: (state) => state.auth.token,
      callStarted: (state) => state.shipcare.callStarted,
      room: (state) => state.twilio.room,
    }),
    browserSupportDialog: {
      get() {
        return this.$store.state.base.browserSupportDialog;
      },
      set(value) {
        this.setBrowserSupportDialog(value);
      },
    },
  },
  async created() {
    //====== INIT STUFF ======

    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        if (error.response.status === 401) {
          await this.logout();
          this.$router.push({ path: "/login" });
        }
        throw error;
      }
    );

    //------ firebase init ------
    const firebaseConfig = {
      apiKey: "AIzaSyCJ8__cbh_Lk1aYjcgnJCExmyMW3AnrEro",
      projectId: "dn2me-production",
      messagingSenderId: "515819616233",
      appId: "1:515819616233:web:a6edf6f2dd83eb5bf5bd78",
    };

    firebase.initializeApp(firebaseConfig);

    this.init();

    //------ login stuff ------
    if (this.isLoggedIn) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${this.token}`;
      this.registerNotifications();
    }

    //------ add to home screen stuff ------
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      this.saveInstallEvent(e);
      this.toggleInstallBtn(true);
    });

    //====== BEFORE UNLOAD LISTENER LISTENER ======
    window.addEventListener("beforeunload", async () => {
      if (this.callStarted) {
        this.room?.disconnect();
      }
    });

    //====== APP STATE CHANGE LISTENER ======

    App.addListener("appStateChange", async (state) => {
      if (state.isActive && !!this.refetchCases) {
        try {
          await this.getCases(true);

          let color;
          let text;

          switch (this.refetchCases) {
            //------ new case event ------
            case "dispatcher_shipcallrequested":
              text = "You have a new case!";
              color = "primary";
              break;
            //------ timeout event ------
            case "ship_shipcalltimeout":
              text = "The ship failed to sespond, the call has timed out.";
              color = "error";
              break;
            //------ ship declined event ------
            case "ship_shipcalldeclined":
              text =
                "The ship declined your invitation and the video has ended.";
              color = "error";
              break;
            //------ doctor accepted event ------
            case "doctor_shipcallaccepted":
              text = "The doctor accepted your invitation and joined the call.";
              color = "success";
              break;
            //------ doctor & ship finished call event ------
            case "ship_shipcallended":
              text = "The doctor and ship finished the videocall.";
              color = "success";
              break;
            //------ doctor & ship finished call event ------
            case "doctor_shipcallended":
              text = "The doctor and ship finished the videocall.";
              color = "success";
              break;
            //------ medical report submitted event ------
            case "doctor_medreport":
              text = "Medical report has been submitted by doctor!";
              color = "success";
              break;
          }

          this.toggleSnackbar({
            open: true,
            text,
            color,
            timeout: 5000,
          });

          this.refetchCases = false;
        } catch (e) {}
      }
    });

    //====== NOTIFICATION STUFF ======
    const messaging = firebase.messaging();

    messaging.onMessage(async (payload) => {
      console.log("Message received directly IN client ", payload);

      //------ new case event ------
      if (payload.data.type == "dispatcher_shipcallrequested") {
        this.toggleSnackbar({
          open: true,
          text: "You have a new case!",
          color: "primary",
          timeout: 5000,
        });

        this.$refs.notif.play();

        this.getCases(true);
      }

      //------ timeout event ------
      if (payload.data.type == "ship_shipcalltimeout") {
        this.toggleSnackbar({
          open: true,
          text: "The ship failed to sespond, the call has timed out.",
          color: "error",
          timeout: 5000,
        });

        this.getCases(true);
      }

      //------ ship declined event ------
      if (payload.data.type == "ship_shipcalldeclined") {
        this.toggleSnackbar({
          open: true,
          text: "The ship declined your invitation and the video has ended.",
          color: "error",
          timeout: 5000,
        });

        this.getCases(true);
      }

      //------ doctor accepted event ------
      if (payload.data.type == "doctor_shipcallaccepted") {
        this.toggleSnackbar({
          open: true,
          text: "The doctor accepted your invitation and joined the call.",
          color: "success",
          timeout: 5000,
        });
      }

      //------ doctor & ship finished call event ------
      if (
        payload.data.type == "ship_shipcallended" ||
        payload.data.type == "doctor_shipcallended"
      ) {
        this.toggleSnackbar({
          open: true,
          text: "The doctor and ship finished the videocall.",
          color: "success",
          timeout: 5000,
        });

        this.getCases(true);
      }

      //------ medical report submitted event ------
      if (payload.data.type == "doctor_medreport") {
        this.toggleSnackbar({
          open: true,
          text: "Medical report has been submitted by doctor!",
          color: "success",
          timeout: 5000,
        });

        this.$refs.notif.play();

        this.getCases(true);
      }
    });

    //------ notification from service worker (when app is backgrounded) ------
    navigator?.serviceWorker?.addEventListener("message", async (event) => {
      const appState = await App.getState();

      if (!appState.isActive) {
        if (
          event?.data?.data?.type == "dispatcher_shipcallrequested" ||
          event?.data?.data?.type == "ship_shipcalltimeout" ||
          event?.data?.data?.type == "ship_shipcalldeclined" ||
          event?.data?.data?.type == "doctor_shipcallaccepted" ||
          event?.data?.data?.type == "ship_shipcallended" ||
          event?.data?.data?.type == "doctor_shipcallended" ||
          event?.data?.data?.type == "doctor_medreport"
        ) {
          this.refetchCases = event?.data?.data?.type; //string is casted to true. check app state change listener
        }

        if (
          event?.data?.data?.type == "dispatcher_shipcallrequested" ||
          event?.data?.data?.type == "doctor_medreport"
        ) {
          this.$refs.notif.play();
        }
      }
    });
  },
  methods: {
    ...mapActions(["registerNotifications", "logout", "getCases", "init"]),
    ...mapMutations([
      "saveInstallEvent",
      "toggleInstallBtn",
      "toggleSnackbar",
      "setBrowserSupportDialog",
    ]),
    onCloseSnackbar() {
      this.toggleSnackbar({
        open: false,
      });
    },
  },
};
</script>

<style lang="scss" >
//====== VUETIFY OVERRIDES ======

.v-main__wrap {
  display: flex;
}

.v-pagination__navigation {
  outline: none;
}

.v-pagination__item {
  outline: none;
}

.v-data-table__selected {
  .v-data-table__checkbox {
    .v-icon {
      color: var(--v-primary-base);
    }
  }
}

.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  padding: 0 4px !important;

  &:first-of-type {
    padding-left: 8px !important;
  }
}

.cases-checkbox {
  .v-input--selection-controls__input {
    background-color: white;
    height: 17px !important;
    width: 17px !important;
    margin-right: 0px !important;
  }
}

.v-btn.v-size--x-small {
  .v-progress-circular {
    height: 15px !important;
    width: 15px !important;
  }
}

//====== GLOBAL STYLES ======
.component-wrapper {
  flex-grow: 1;
  padding: 16px;
}

.data-card {
  flex: 1;
}

.local,
.remote {
  overflow: hidden;

  video {
    height: 100%;
    width: 100%;
  }
}

.link {
  cursor: pointer;
  color: var(--v-primary-base);
  transition: all 0.2s;

  &:hover {
    text-decoration: underline;
    color: var(--v-primary-lighten1);
  }
}
</style>